import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { CheckCircleRounded } from '../Icon'

const SelectButton = ({ onClick, ...rest }) => {
	return (
		<Button variant="control" onClick={onClick} {...rest}>
			<CheckCircleRounded />
			Select
		</Button>
	)
}

export default SelectButton
