import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import { Box, Flex } from '@chakra-ui/react'
import { SliderArrow } from '../../../atoms/SliderArrow'
import ThemeItem from '../../SceneryTheme/src/ThemeItem'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '../../../atoms/Icon'

const ThemeItemWrapper = (props) => {
	return (
		<Flex w="216px" maxW="216px" pl={['1rem', '0.125rem']} pb="0.125rem" pr={['0.125rem', '1rem']}>
			<ThemeItem {...props} w="200px" />
		</Flex>
	)
}

const ThemeSlider = ({ collection, ...rest }) => {
	const navigate = useNavigate()
	const { uuid } = useParams()

	const handleScrollTop = () => {
		if (typeof window != 'undefined') window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	const thumbItems = collection.map((el, i) => {
		return (
			<ThemeItemWrapper
				key={i}
				item={el}
				onClick={() => {
					navigate(`/event/${uuid}/scenery/theme/${el.id}`)
					handleScrollTop()
				}}
			/>
		)
	})

	// Carousel
	let Carousel

	return (
		<Box position="relative" mx="auto" minH="80px">
			<SliderArrow
				direction="left"
				aria-label="Previous"
				icon={<ArrowBackIosNewRounded w="1rem" h="1rem" color="link" />}
				onClick={() => Carousel.slidePrev()}
			/>

			<SliderArrow
				direction="right"
				aria-label="Next"
				icon={<ArrowForwardIosRounded w="1rem" h="1rem" color="link" />}
				onClick={() => Carousel.slideNext()}
			/>

			<AliceCarousel
				ref={(el) => (Carousel = el)}
				items={thumbItems}
				activeIndex={0}
				autoWidth
				infinite
				disableDotsControls
				disableButtonsControls
				touchMoveDefaultEvents={false}
				swipeDelta={5}
				mouseTracking={false}
				touchTracking
			/>
		</Box>
	)
}

export default ThemeSlider
