import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, AspectRatio } from '@chakra-ui/react'

const ModalCollageLearnHow = ({ onClose, isOpen }) => {
	const src = 'https://static.vidday.com/videos/Collage_Explainer_Video_compressed.mp4'

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />

			<ModalContent p="0">
				<ModalCloseButton color="white" top="-2.5rem" right="0" />
				<ModalBody>
					<AspectRatio w="100%" maxW="720px" ratio={16 / 9}>
						<video
							autoPlay
							width="100%"
							height="auto" //autoPlay loop muted
							controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
							// poster={preview?.signedThumbnailUrl}
							onError={(error) => console.error(error)}
							controlsList={['nodownload']}
							onContextMenu={(e) => {
								e.preventDefault()
							}}>
							<source src={src} type={'video/mp4'} />
						</video>
					</AspectRatio>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalCollageLearnHow
