import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../atoms/Card'
import {
	Box,
	Container,
	AspectRatio,
	Image,
	IconButton,
	Text,
	Flex,
	Button,
	HStack,
	useDisclosure,
} from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import SelectButton from '../../atoms/nav-controls/SelectButton'
import { Helmet } from 'react-helmet'
import NavBar from '../../atoms/NavBar'
import { useEventContext } from '../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import { useAutomationTemplates } from '../../../hooks/useAutomationTemplates'
import { useAuthContext } from '../../../contexts/AuthProvider'
import ThemeSlider from './src/ThemeSlider'
import { updateEvent } from '../../../../api/app/events'
import FooterBase from '../../molecules/FooterBase'
import { useDispatch } from 'react-redux'
import ModalCollageLearnHow from '../../organisms/ModalCollageLearnHow'
import loadable from '@loadable/component'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import { FeatureItemFullWidth } from '../../molecules/FeatureItem'
const ViddayVideoPlayer = loadable(() => import(/* webpackPrefetch: true */ '../../organisms/VideoPlayer'))
import { PlayArrowRounded, PauseRounded } from '../../atoms/Icon'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'

const featureItem = {
	path: '/assets/images/graphics/vidday-feature-gift-wrap',
	content: () => (
		<>
			<Text variant="title">Did you know?</Text>
			<Text pt=".25rem">
				Finalized videos are wrapped in a beautiful gift wrap effect with many designs to choose from.
			</Text>
		</>
	),
	href: 'https://help.vidday.com/whats-the-gift-wrap-effect/',
}

const SceneryThemeIndividual = () => {
	useScrollTopOnMount()
	const navigate = useNavigate()
	const { event } = useEventContext()
	const { isAdmin } = useAuthContext()
	const handlers = useEventHandlersV2(event.uuid)
	const { themeId } = useParams()
	const [item, setItem] = useState(null)
	const dispatch = useDispatch()
	const [isCollage, setIsCollage] = useState(false)
	/**
	 * Theme Automation
	 */
	const { selectableTemplates } = useAutomationTemplates(event)

	useEffect(() => {
		const _item = selectableTemplates.find((el) => el.id == themeId)
		setItem(_item)
		const _isCollage = _item && _item.id.includes('collage') ? true : false
		setIsCollage(_isCollage)
	}, [themeId, selectableTemplates])

	const { isOpen, onClose, onOpen } = useDisclosure()

	/**
	 * Callback to save the newly selected theme.
	 */
	const handleSaveSelectedTheme = () => {
		if (event.themeTemplateId != item.id) {
			dispatch(
				updateEvent({
					id: event.id,
					themeTemplateId: item.id,
				})
			)
		}
		redirectToNextIfWindow(handlers.customize)
		// navigate(handlers.customize)
	}

	const videoRef = useRef(null)

	let location = item && `${item.signedLocationUrl}`
	let poster = item && `${item.signedThumbnailUrl}`
	const [play] = useState(false)

	if (item) {
		return (
			<div key={item.label}>
				<Helmet>
					<title>VidDay - Video Theme</title>
				</Helmet>

				<ModalCollageLearnHow isOpen={isOpen} onClose={onClose} />

				<NavBar position="fixed">
					<HStack spacing={['4%', '1rem']}>
						<BackButton onClick={() => navigate(-1)} />
					</HStack>
					<HStack spacing={['4%', '1rem']} justifyContent="flex-end">
						<SelectButton onClick={handleSaveSelectedTheme} />
					</HStack>
				</NavBar>

				<Container maxW="container.lg" py="1rem" variant="main">
					<Box position="relative" zIndex="1" mb={isCollage ? 0 : '1rem'}>
						<Card px={['1rem', '2rem']} py="2rem" variant="main">
							<ViddayVideoPlayer
								play={play}
								cacheBustedSrc={item.signedSourceUrl}
								data={item}
								sources={[
									{
										src: item.video,
										type: 'video/mp4',
										size: '360',
									},
									// {
									// 	src:location,
									// 	type:'video/mp4',
									// 	size:'720'
									// },
									// {
									// 	src:location,
									// 	type:'video/mp4',
									// 	size:'1080'
									// }
								]}
								videoRef={videoRef}
								// poster={poster}
								// currentRotation={currentRotation}
								// timeRange={timeRange}
								// duration={data.duration}
								// muted={muted}
								// onMuteAudio={onMuteAudio}
								nativeControls={false}
								// showTrimmer
								hideVolume={true}
								// hideLoop={true}
								maxHeight={true}
								maxWidth={true}
								volume={1}
							/>

							<Flex w="full" align="center" justify="space-between" mt="2rem" maxW="720px" mx="auto">
								<Flex>
									<Text>
										<strong>{item.label}</strong>
									</Text>
								</Flex>
								<Flex>
									<Button variant="outline" onClick={handleSaveSelectedTheme}>
										Use This Theme
									</Button>
								</Flex>
							</Flex>
						</Card>
					</Box>

					{isCollage && (
						<HStack
							maxW="400px"
							borderRadius="base"
							p="1rem"
							pt="2rem"
							position="relative"
							zIndex="0"
							top="-1rem"
							alignItems="center"
							spacing="1rem"
							margin="0 auto"
							bg="gray.100"
							onClick={onOpen}
							_hover={{ cursor: 'pointer' }}>
							<Flex minW="59px" w="59px" h="59px" align="center" justify="center">
								<AspectRatio w="59px" maxW="59px" ratio={1} borderLeftRadius="base">
									<Image
										src="/assets/images/graphics/collage-explainer-bg.png"
										borderLeftRadius="base"
									/>
									{/* {playing ? <Button onClick={handlePause}>Pause</Button> : <Button onClick={handlePlay}>Play</Button>} */}
								</AspectRatio>
								<IconButton
									variant="iconMenu"
									w="2rem"
									minW="2rem"
									maxW="2rem"
									h="2rem"
									position="absolute"
									aria-label="Play Video"
									icon={isOpen ? <PauseRounded /> : <PlayArrowRounded />}
									// onClick={handlePlay}
								/>
							</Flex>

							<Text>
								<strong>Learn how</strong> Collage themes work with this 1-minute video.
							</Text>
						</HStack>
					)}

					<ThemeSlider collection={selectableTemplates} />
				</Container>
				<Container maxW="container.lg">
					<FeatureItemFullWidth item={featureItem} />
				</Container>

				<FooterBase />
			</div>
		)
	}
	return <></>
}

export default SceneryThemeIndividual
